import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  ReactNode,
} from "react"

// == Types =====================================================
type PopoverId = string
type SetIsPopoverOpenFn = (isPopoverOpen: boolean) => void
type PopoverRef = {
  [key: PopoverId]: SetIsPopoverOpenFn
}
type PopoverContextValue = {
  register: (setIsPopoverOpen: SetIsPopoverOpenFn) => PopoverId
  open: (id: PopoverId) => void
  close: () => void
  remove: (id: PopoverId) => void
}
type DefaultValue = undefined
type ContextValue = PopoverContextValue | DefaultValue

// == Context =====================================================
export const PopoverContext = createContext<ContextValue>(undefined)

// == Provider =====================================================
interface PopoverProviderProps {
  children: ReactNode
}
const PopoverProvider = ({ children }: PopoverProviderProps) => {
  // The popover provider keeps track of the state of all the popovers in the dom.
  // Popovers will register and remove themselves using the usePopoverState hook.
  const popovers = useRef<PopoverRef>({})

  const register = useCallback<PopoverContextValue["register"]>(
    setIsPopoverOpen => {
      let id = Math.random().toString()
      while (Object.keys(popovers).includes(id)) {
        id = Math.random().toString()
      }

      popovers.current = {
        ...popovers.current,
        [id]: setIsPopoverOpen,
      }

      return id
    },
    []
  )

  const remove = useCallback<PopoverContextValue["remove"]>(id => {
    const { [id]: removedPopover, ...rest } = popovers.current

    popovers.current = rest
  }, [])

  const closeAllPopovers = useCallback(() => {
    Object.values(popovers.current).forEach(setIsPopoverOpen =>
      setIsPopoverOpen(false)
    )
  }, [])

  const open = useCallback<PopoverContextValue["open"]>(
    id => {
      closeAllPopovers()

      const setIsPopoverOpen = popovers.current[id]
      setIsPopoverOpen(true)
    },
    [closeAllPopovers]
  )

  const close = useCallback<PopoverContextValue["close"]>(() => {
    closeAllPopovers()
  }, [closeAllPopovers])

  const value = useMemo<PopoverContextValue>(
    () => ({
      register,
      open,
      close,
      remove,
    }),
    [register, open, close, remove]
  )

  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  )
}

export const usePopoverProvider = () =>
  useContext(PopoverContext) as PopoverContextValue

export const usePopoverState = () => {
  // usePopoverState manages an individual popover's state,
  // as well as registering and removing itself from the popover provider.
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [id, setId] = useState<string>()
  const {
    register,
    remove,
    open: ctxOpen,
    close: ctxClose,
  } = usePopoverProvider()

  useEffect(() => {
    // on mount, register popover
    const registeredId = register(setIsOpen)
    setId(registeredId)

    // on unmount, remove popover
    return () => remove(registeredId)
  }, [register, remove])

  // now that we have an ID for the popover, here are some fns we can use
  const open = useCallback(() => {
    if (typeof id !== "undefined") {
      ctxOpen(id)
    }
  }, [id, ctxOpen])

  const close = useCallback(() => {
    ctxClose()
  }, [ctxClose])

  const toggle = useCallback(() => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }, [isOpen, open, close])

  return {
    isOpen,
    open,
    close,
    toggle,
  }
}

export default PopoverProvider
