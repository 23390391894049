const React = require("react")
const FadeInWrapper = require("./src/components/FadeInWrapper").default
const Store = require("./src/context/Store").default

exports.wrapPageElement = ({ element, props }) => {
  const { path } = props
  return (
    <>
      <Store>
        <FadeInWrapper path={path}>
          {element}
        </FadeInWrapper>
      </Store>
    </>
  )
}
