import React, {ReactNode} from 'react';
import PopoverProvider from './PopoverContext';

type StoreProps = {
  children: ReactNode
}
const Store = ({ children }: StoreProps) =>
  <PopoverProvider>
    {children}
  </PopoverProvider>
export default Store