import React, { useEffect } from "react"
import Helmet from "react-helmet"

import "./fadeIn.scss"

const fadeInElements = () => {
  if (typeof document === "undefined") {
    return
  }

  // Polyfill for-each
  if (
    typeof window !== "undefined" &&
    window.NodeList &&
    !NodeList.prototype.forEach
  ) {
    NodeList.prototype.forEach = Array.prototype.forEach
  }

  // Fade in elements
  var elements = document.querySelectorAll("[data-fade]")
  elements.forEach(element =>
    element.setAttribute("data-fade-visible", "visible")
  )
}

const FadeInWrapper = ({ children, path }) => {
  useEffect(() => {
    // whenever we navigate to a new path,
    // we fade in its elements
    fadeInElements()
  }, [path])

  return (
    <>
      <Helmet>
        <noscript>{`<style type="text/css">[data-fade] {opacity: 1;}</style>`}</noscript>
        {/*
        Helmet allows us to inject code into the HTML so that it will run
        before the react bundle is loaded.
        It's important that content that fades in is visible immediately,
        so we run that code here before handing off responsibility to the effect above.
        */}
        <script>{`
        if (typeof document !== 'undefined') {
          document.addEventListener("DOMContentLoaded", ${fadeInElements})
        }
        `}</script>
      </Helmet>
      {children}
    </>
  )
}
export default FadeInWrapper
