exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tags-js": () => import("./../../../src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magicyte-index-js": () => import("./../../../src/pages/magicyte/index.js" /* webpackChunkName: "component---src-pages-magicyte-index-js" */),
  "component---src-pages-magicyte-mb-carousel-js": () => import("./../../../src/pages/magicyte/mb/carousel.js" /* webpackChunkName: "component---src-pages-magicyte-mb-carousel-js" */),
  "component---src-pages-magicyte-mb-index-js": () => import("./../../../src/pages/magicyte/mb/index.js" /* webpackChunkName: "component---src-pages-magicyte-mb-index-js" */),
  "component---src-pages-magicyte-mb-video-js": () => import("./../../../src/pages/magicyte/mb/video.js" /* webpackChunkName: "component---src-pages-magicyte-mb-video-js" */),
  "component---src-pages-magicyte-review-js": () => import("./../../../src/pages/magicyte/review.js" /* webpackChunkName: "component---src-pages-magicyte-review-js" */),
  "component---src-pages-magicyte-sc-index-js": () => import("./../../../src/pages/magicyte/sc/index.js" /* webpackChunkName: "component---src-pages-magicyte-sc-index-js" */),
  "component---src-pages-sanibit-index-js": () => import("./../../../src/pages/sanibit/index.js" /* webpackChunkName: "component---src-pages-sanibit-index-js" */),
  "component---src-pages-sanibit-review-js": () => import("./../../../src/pages/sanibit/review.js" /* webpackChunkName: "component---src-pages-sanibit-review-js" */),
  "component---src-templates-blog-list-page-all-js": () => import("./../../../src/templates/blogListPageAll.js" /* webpackChunkName: "component---src-templates-blog-list-page-all-js" */),
  "component---src-templates-blog-list-page-tags-js": () => import("./../../../src/templates/blogListPageTags.js" /* webpackChunkName: "component---src-templates-blog-list-page-tags-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

